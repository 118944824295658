/* Sen */

@font-face {
  font-family: "Sen";
  src: local("Sen"), url("./fonts/Sen/Sen-Regular.ttf") format("truetype");
  font-display: swap;
}

/* Montserrat */

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}
